import { graphql, Link, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import React from 'react';
import cn from 'classnames';
import styles from './header.module.scss';

const navItemsData = [
  {
    name: '首页',
    link: '/'
  }, {
    name: '新媒体代运营',
    link: '/new-media'
  }, {
    name: '开发与代运营',
    link: '/development'
  }, {
    name: '健康测评',
    link: '/health-assessment'
  }, {
    name: '关于我们',
    link: '/about'
  }
];

function NavItem({ name, link, active }) {
  return <li className={cn({ [styles.active]: active })}>
    <Link to={link}>{name}</Link>
  </li>
}

function Nav({ activePage }) {
  return <ul className={styles.nav}>
    {navItemsData.map((nav, i) => <NavItem key={i} {...nav} active={nav.link === activePage} />)}
  </ul>;
}

export default function Header({ pageKey }) {
  const { logo } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: {eq: "logo.png"}) {
        childImageSharp {
          fixed(width: 120) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `);

  return <header className={styles.header}>
    <div className={styles.logo}>
      <Link to="/">
        <Image fixed={logo.childImageSharp.fixed} />
      </Link>
    </div>
    <Nav activePage={pageKey} />
  </header>;
}
