import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

export default function SEO({ title, description, meta, lang }) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
    }
  `);

  const metaDescription = description || site.siteMetadata.description;

  return <Helmet
    htmlAttributes={{ lang }}
    title={title}
    titleTemplate={`%s | ${site.siteMetadata.title}`}
    meta={[
      {
        name: 'description',
        content: metaDescription,
      }, {
        property: `og:title`,
        content: title,
      }, {
        property: `og:description`,
        content: metaDescription,
      }, {
        property: `og:type`,
        content: `website`,
      }
    ].concat(meta)}
  />
}

SEO.defaultProps = {
  lang: `zh-CN`,
  meta: [],
  description: ``,
}
