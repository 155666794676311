import React from 'react';
import styles from './consulting-section.module.scss';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

export default function ConsultingSection() {
  const { background } = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "consulting-bg.jpg" }) {
        childImageSharp {
          fixed(width: 1440, height: 335) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  return <section className={styles.section}>
    <div className={styles.bg}>
      <Image fixed={background.childImageSharp.fixed} />
    </div>

    <div className={styles.content}>
      <div className={styles.text}>
        <h3 className={styles.title}>让「医疗健康」的互联网化运营更专业！</h3>
        <button className={styles.button}>立即咨询</button>
      </div>
    </div>
  </section>
}
