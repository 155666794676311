import React from 'react';
import Image from 'gatsby-image';
import styles from './footer.module.scss';
import { useStaticQuery, graphql, Link } from 'gatsby';

function ContactUs({ logo }) {
  return <div className={styles.contactUs}>
    <div className={styles.logo}>
      <Image fixed={logo.childImageSharp.fixed} />
    </div>
    <div className={styles.companyName}>上海葆康医疗管理有限公司</div>
    <div className={styles.address}>地址：上海市奉贤区奉浦大道518号6幢</div>
  </div>
}

function FooterCol({ title, children }) {
  return <div className={styles.col}>
    <div className={styles.colTitle}>{title}</div>
    <div className={styles.colBody}>{children}</div>
  </div>
}

function FooterLinks({ links }) {
  return <ul className={styles.links}>
    {links.map((link, i) => <li className={styles.link} key={i}>
      {link.url.startsWith('/') ? <Link to={link.url}>{link.text}</Link> : <a href={link.url} target="_blank" rel="noreferrer">{link.text}</a>}
    </li>)}
  </ul>
}

const shortcutLinks = [
  {
    text: '新媒体代运营',
    url: '/new-media',
  }, {
    text: '小程序制作与运营',
    url: '/development',
  }, {
    text: '官网制作与运营',
    url: '/development',
  }, {
    text: 'APP/互联网医院代运营',
    url: '/development',
  }, {
    text: '科研美学服务',
    url: '/'
  }, {
    text: '健康测评',
    url: '/health-assessment',
  }, {
    text: '关于我们',
    url: '/about',
  }
];

const friendLinks = [
  {
    text: '医院品牌指数',
    url: 'https://y.dxy.cn/dxyIndex',
  }, {
    text: '丁香园',
    url: 'https://dxy.cn/'
  }, {
    text: '好大夫',
    url: 'https://www.haodf.com/'
  }, {
    text: '微脉',
    url: 'https://www.myweimai.net/'
  }, {
    text: '微医',
    url: 'https://www.guahao.com/'
  }
];

export default function Footer() {
  const { logo, qrcode } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo-light.png" }) {
        childImageSharp {
          fixed(width: 101, height: 73) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      },
      qrcode: file(relativePath: { eq: "qrcode-service.jpeg" }) {
        childImageSharp {
          fixed(width: 173, height: 243) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  return <section className={styles.footer}>
    <div className={styles.footerContent}>
      <ContactUs logo={logo} />
      <div className={styles.row}>
        <FooterCol title="快捷通道">
          <FooterLinks links={shortcutLinks} />
        </FooterCol>
        <FooterCol title="友情链接">
          <FooterLinks links={friendLinks} />
        </FooterCol>
        <FooterCol title="联系我们">
          <div>- 7X12小时客服支持</div>
          <div>- 1V1专业顾问咨询</div>
          <div className={styles.tel}>
            联系电话：<br /><em>191-2151-9504</em>
          </div>
          <div>微信扫码，立即咨询！</div>
        </FooterCol>
      </div>
      <div className={styles.wechat}>
        <Image fixed={qrcode.childImageSharp.fixed} alt="微信扫码，立即咨询" />
      </div>
    </div>
    <div className={styles.copyright}>
      <p>版权所有 © 上海葆康医疗管理有限公司</p>
      <p><a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">沪ICP备20023409号-1</a></p>
    </div>
  </section>;
}
