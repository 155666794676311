import cn from 'classnames';
import React from 'react';
import ConsultingSection from './consulting-section';
import Footer from './footer';
import Header from './header';
import styles from './page-layout.module.scss';

export default function PageLayout({ className, pageKey, children, ...rest }) {
  return <div className={cn(styles.page, className)} {...rest}>
    <Header pageKey={pageKey} />
    <main>
      {children}
      <ConsultingSection />
    </main>
    <Footer />
  </div>
}
